<template>
    <div class="produttore">
        <div class="container">


            <div class="img" :style="{  backgroundImage: 'url(' + logoSource + ')' }">
            </div>


            <div class="content">
                <h3>{{ proddata.summary }}</h3>
                <p v-html="proddata.processed"></p>
            </div>


            <div class="link">
                <router-link :to="slug">{{ this.lang=='it' ? 'Approfondisci' : 'Discover more'}}<img src="../assets/icon-arrow.svg" alt=""></router-link>
            </div>

        
        </div>
    </div>
</template>

<script>
import { slugify } from '../libs/utils'
export default {
    name: 'produttore',
    data: () => {
        return {

        }
    },
    props: {
        proddata: Object,
        logo: String
    },
    computed: {
        logoSource() {
            return this.logo
        },
        slug() {
            return '/' + slugify(this.proddata.summary.toLowerCase())
        },
        lang() {
            return this.$store.state.currentLang
        }
    }
}
</script>

<style lang="scss" scoped>
@import '../styles/colors.scss';
a {
    text-decoration: none;
}

.produttore {
    padding: 5rem 0;
    border-bottom: 1px solid #e1e1e1;
    .container {
        display: flex;
        flex-direction: row;
    }
    .img {
        width: 18%;
        overflow: hidden;
        position: relative;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        margin-right: 4rem;
        img {
            width: auto;
            height: 100%;
            position: absolute;
        }
    }
    .content {
        display: flex;
        width: 50%;
        flex-direction: column;
        h3 {
            color: #000;
            font-size: 3.125rem;
            margin-bottom: 1rem;
        }
        p {
            width: 95%;
        }
    }
    .link {
        width: 25%;
        display: flex;
        align-items: center;
        justify-content:flex-end;
        transition: all 0.5s;
        a { color: $mainColor; } 
        img {
            margin-left: 10px;
        }
    }

    &:hover {
        background: #fff;
        h3 {color: $mainColor;}
        .link {
            opacity: 1;
        }
    }
}

@media (max-width:1200px){
    .produttore .img{
        min-width: 190px;
    }
}

@media (max-width:992px){
        .produttore {
            padding: 2rem 0;
            .container{
            flex-direction: column;
            .content{
                width: auto;
            }
            .img {
                height: 150px;
                width: auto;
                margin-right: 0;
                margin-bottom: 2rem;
            }
        }
        .link {
            margin-top:2rem ;
            margin-bottom: 2rem;
            width: auto;
            justify-content: flex-start;
        }
    }
}

@media (max-width:768px){
    .produttore .content{
        h3 {
            font-size: 2.5rem;
        }
    }
    
}

@media (max-width:576px){
    .produttore .content{
        h3 {
            font-size: 2.125rem;
        }
    }
}
</style>
<template>
    <basic-page class="chisiamo" :pagedata="this.pageData" bannerimg="/img/chi-siamo-banner.jpg">
        <div class="container-beige intro container">
            <h3 class="occhiello occhiello-beige">
                Polo del Gusto
            </h3>
            <h2>
                {{ lang=='it' ? 'Eccellenze del gusto' : 'Excellence of taste'}}
            </h2>
        </div>

        <div class="container" v-if="this.pageData.field_page_ui_testo && this.pageData.field_page_ui_testo[0]">
            <p class="role role-2">{{ lang=='it' ? 'Filosofia' : 'Philosophy'}}</p>
            
            <div class="central row-box">
                <div class="row">
                    <div class="column text-container">
                        <h2 class="section-title">{{ this.pageData.field_page_ui_testo[0].attributes.title }}</h2>
                        <p v-html="this.pageData.field_page_ui_testo[0].attributes.field_ui_testo[0].processed"></p>
                    </div>
                    <div class="img img-0" v-parallax="-0.3"><img src="../assets/filigrana-chi-siamo-1.png" width="375" height="auto" alt=""></div>
                </div>
                <div class="row text-container">
                    <div class="img img-1" v-parallax="-0.1"><img src="../assets/chi-siamo-img-1.jpg" width="375" height="auto" alt="">
                    </div>
                    <div class="column-2">
                        <p v-html="this.pageData.field_page_ui_testo[0].attributes.field_ui_testo[1].processed"></p>
                    </div>
                </div>
                <div class="row-video" v-parallax="-0.4">
                    <div :class="['video-container',{videoPlaying:isPlaying}]" ref="videoContainer"
                        @click="playVideo()">
                        <div class="controls" ref="videoControls">
                            <img class="play" src="../assets/play-button.svg" width="71" height="71" alt="">
                            <img class="pause" src="../assets/pause-button.svg" width="71" height="71" alt="">
                        </div>
                        <video ref="video" @ended="onEnd()">
                            <source src="../assets/video/Macchione_PolodelGusto_2021_def.mp4" type="video/mp4">
                            <source src="../assets/video/Macchione_PolodelGusto_2021_def.webm" type="video/webm">
                        </video>
                    </div>
                </div>
            </div>
        </div>

        <div class=" bg-gray section-produttori" v-if="this.pageData.field_page_ui_testo && this.pageData.field_page_ui_testo[2]">
            
            <div class="container">
                <p class="role role-2">I Marchi</p>
                <h2 class="section-title">{{ this.pageData.field_page_ui_testo[2].attributes.title }}</h2>
            </div>

            <div class="central produttori" v-if="brandsData.length > 0">
                <router-link :to="slugLink(p.summary)" v-for="(p, index) in this.pageData.field_page_ui_testo[2].attributes.field_ui_testo" :key="'produttore'+index">
                     <produttore :proddata="p" :logo="urlLoghiBrands[index]">
                    </produttore> 
                </router-link>
            </div>

        </div>

        <div class="container">
            <p class="role">{{ lang=='it' ? 'Il Polo oggi' : 'Il Polo today'}}</p>
            <div class="central" v-if="this.pageData.field_page_ui_testo && this.pageData.field_page_ui_testo[1]">
                <div class="main-block-left">
                    <div class="column text-container">
                        <h2 class="section-title">{{ this.pageData.field_page_ui_testo[1].attributes.title }}</h2>
                        <p v-html="this.pageData.field_page_ui_testo[1].attributes.field_ui_testo[0].processed"></p>
                    </div>
                    <div class="img" v-parallax="-0.4">
                        <!-- <img src="../assets/fatturato_aggiunta_dati.png" alt=""> -->

                        <img v-if="lang=='it'" src="../assets/Fatturato_2022-IT.png" alt="">
                        <img v-if="lang=='en'" src="../assets/Fatturato_2022-ENG.png" alt="">

                        
                        <div class="didascalia">
                            {{ lang=='it' ? 
                                'Il fatturato dal 2008 al 2018 è un dato aggregato delle aziende pre-costituzione Polo del Gusto.' :
                                'The turnover from 2008 to 2018 is an aggregate figure of the pre-establishment Polo del Gusto companies.'
                            }}
                        </div>
                    </div>
                </div>
            </div>

            <p class="role">{{ lang=='it' ? 'Il Polo domani' : 'Il Polo tomorrow'}}</p>
            <div class="central" v-if="this.pageData.field_page_ui_testo && this.pageData.field_page_ui_testo[3]">
                <div class="main-block-right">
                    <div class="img title-img" ><img src="../assets/chi-siamo-2.jpg" alt=""></div>
                    <div class="column text-container">
                        <h2 class="section-title">{{ this.pageData.field_page_ui_testo[3].attributes.title }}</h2>
                        <p v-html="this.pageData.field_page_ui_testo[3].attributes.field_ui_testo[0].processed"></p>
                    </div>
                </div>
                <div class="images-container">
                    <div class="img img-3" v-parallax="-0.2">
                        <img src="../assets/agrimontana-candissoire.jpg" width="389" height="346" alt="">
                    </div>
                    <div class="img img-4" v-parallax="-0.4">
                        <img src="../assets/chi-siamo-4.jpg" width="778" height="521" alt="">
                    </div>
                </div>
            </div>
        </div>
    </basic-page>
</template>

<script>
    import Produttore from '../components/Produttore.vue'
    import { fetchSinglePage, fetchNodes, getTranslation } from '../libs/drupalClient'
    import { slugify } from '../libs/utils'
    import BasicPage from './BasicPage.vue'
    export default {
        components: {
            Produttore,
            BasicPage
        },
        name: 'chi-siamo',
        data: () => {
            return {
                currentPageId: "625c3575-81eb-46f0-8fb5-635f66856dd5",
                pageData: { attributes: {} },
                brandsData: [],
                p1: 0,
                p2: 0,
                mouseX: 0,
                mouseY: 0,
                isPlaying: false,
                asd:"resr",
                urlLoghiBrands:[
                    "https://admin.polodelgusto.com/sites/default/files/achillea-logo.jpg",
                    "https://admin.polodelgusto.com/sites/default/files/logo-agrimontana_1.jpg",
                    "https://admin.polodelgusto.com/sites/default/files/dammann-logo.jpg",
                    "https://admin.polodelgusto.com/sites/default/files/logo%20domori.png",
                    "https://admin.polodelgusto.com/sites/default/files/LOGO-PINTAUDI.png"
                ]
            }
        },
        computed: {
            lang() {
                return this.$store.state.currentLang
            }
        },
        methods: {
            slugLink(name) {
                return slugify(name);
            },
            splittedTitle: function (value) {
                return value.replace(/(?![^<]*>)[^<]/g, c => `<span>${c}</span>\n`);
            },
            play() {
                this.$refs.video.play();
            },
            pause() {
                this.$refs.video.pause();
            },
            onEnd() {
                this.isPlaying = false
            },
            playVideo() {
                if (!this.isPlaying) {
                    this.play()
                    this.isPlaying = true
                } else {
                    this.isPlaying = false
                    this.pause()
                }
            },
        },
        watch: {
            lang() {
                getTranslation(this.$route.name, this.lang).then(res => {
                    this.$router.push({path: res})
                })


                fetchSinglePage(this.currentPageId, this.lang).then(res => {
                    this.pageData = res[0]
                    this.pageData.field_page_image = ''
                })


                fetchNodes('brand', {
                }, this.lang)
                    .then(res => {
                    this.brandsData = res

                    console.log('this.brandsData ',this.brandsData);
                    
                })

            }
        },
        mounted() {
            fetchSinglePage(this.currentPageId, this.lang).then(res => {
                this.pageData = res[0]
                this.pageData.field_page_image = ''
            })

            fetchNodes('brand', {
                include: ['field_brand_logo']
            }, this.lang)
                .then(res => {
                this.brandsData = res

                console.log('this.brandsData ',this.brandsData);
            })
        }

    }
</script>

<style lang="scss" scoped>
    @import "../styles/colors";

.images-container .img-3 img {
    width: 389px;
}

.didascalia {
    position: relative;
    //bottom: 40px;
    width: 100%;
    font-weight: 700;
    color: $mainColor;
}


    .images-container {
        display: flex;
        flex-direction: row;
        margin-top: -50px;
        margin-bottom: 4rem;

        img {
            width: 100%;
            height: auto;
        }

        .img {
            position: relative;
        }

        .img-4 {
            top: 100px;
            left: -80px;
            z-index: -1;
        }
    }

    .section-produttori {
        margin-top: 4rem;

        .section-title {
            max-width: 500px;
            margin-bottom: 3rem;
        }
    }


    .filigrana {
        position: absolute;
        right: -200px;
        top: -200px;
    }

    .filigrana-2 {
        right: auto;
        top: auto;
        bottom: -500px;
        opacity: 0.051;
        left: -200px;
    }

    .central {
        display: flex;
        position: relative;
        flex-direction: column;
        margin-bottom: 6rem;
        margin-left: auto;
        margin-right: auto;
    }

  

    .row-box {

        display: flex;
        flex-direction: column;

        .row {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
        }



        .img {
            width: 50%;
            overflow: hidden;
            min-width: 300px;
            height: 425px;
            position: relative;

            img {
                width: 100%;
                height: auto;
                position: absolute;
            }
        }

        .img-1 {
            height: 560px;
        }

        .img-0 {
            width: 550px;
            margin: 0 auto;
            z-index: 1000;
            height: auto;
            overflow: unset;
        }



        .column,
        .column-2 {
            display: flex;
            flex-direction: column;
            position: relative;
            width: 50%;

            .role {
                position: relative;

                &:after {
                    content: "";
                    width: 120px;
                    height: 1px;
                    position: absolute;
                    background-color: $mainColor;
                    left: 12%;
                    top: 13px;
                }
            }
        }

        .column p,
        .column .img {
            margin-left: 0;
            margin-right: 50px;
        }

        .column-2 {

            p,
            .img {
                margin-right: 0;
                margin-left: 50px;
            }
        }

        .row-video {
            position: relative;
            width: 100%;
            margin-bottom: 6rem;

            .video-container {
                position: absolute;
                bottom: -160px;
                right: 5%;
                width: 701px;
                height: 394px;
            }
        }
    }

    .container {
        .role {
            position: relative;
            margin-top: 4rem;
            margin-bottom: 5rem;
            width: fit-content;
            font-size: 1rem;

            &:after {
                content: "";
                width: 120px;
                height: 1px;
                position: absolute;
                background-color: $mainColor;
                right: -150px;
                top: .75rem;
            }
        }


    }

    .produttori {
        display: flex;
        flex-direction: column;
        margin-bottom: 2rem;

        a {
            display: block;
            text-decoration: none;
        }

        h2 {
            width: 50%;
        }
    }

    .main-block-left {
        .column {
            width: 46%;
        }

        .img {
            width: 100%;
            //overflow: hidden;
            height: 100%;
            position: relative;
            margin-left: auto;
            margin-top: -200px;
            z-index: -1;

            img {
                width: 100%;
                //margin-left: 40%;
                right: 0;
                height: auto;
                position: relative;
            }
        }
    }

    .main-block-right {
        position: relative;
        display: flex;

        .column {
            width: 50%;
            margin-left: 10%;
        }

        .img {
            position: relative;
            z-index: -1;
        }
    }


    @media (max-width:1200px) {
        .row-box .row-video .video-container {
            bottom: -10px;
            width: 491px;
            height: 276px;
            right: 0;
        }
    }

    @media (max-width:992px) {
        .didascalia {
            position: relative;
            left: 0;
            bottom: 0;
            font-weight: 500;
            width: 100%;
        }
        .container .role {
            margin-bottom: 4rem;
        }
        p {
            margin-top: 1rem;
            margin-bottom: 2rem;
        }

        .row-box .row {
            flex-direction: column;
        }

        .container .role {
            margin-top: 2rem;
        }

        .row-box .column,
        .row-box .column-2 {
            width: auto;
        }

        .row-box .img {
            width: 100%;
        }

        .row-box p {
            margin-top: 1rem;
            margin-bottom: 2rem;
        }

        .row-box .column p,
        .row-box .column .img,
        .row-box .column-2 p,
        .row-box .column-2 .img {
            margin-right: 0;
            margin-left: 0px;
        }

        .row-box .img-1 {
            height: auto;
            width: 100%;

            img {
                position: relative;
            }
        }

        .row-box .row-video .video-container {
            position: relative;
            width: 100%;
            height: 100%;
        }

        .central {
            margin-bottom: 0rem;
        }

        .section-produttori {
            margin-top: 0rem;
        }

        .section-produttori .section-title {
            margin-bottom: 2rem;
        }

        .main-block-left .column {
            width: auto ;
        }

        .main-block-left .img{
            margin-top: 0;
            //width: auto;
            img {
                width: 100%;
                margin-left: 0;
            }
        }

        .main-block-right {
            flex-direction: column;
            .img {
                margin-bottom: 2rem;
            }
            .column {
                width: auto;
                margin-left: 0%;
            }
        }
        .images-container {
            margin-top: 0px;
            margin-bottom: 4rem;
        }

    }

  @media (max-width:992px) {
    .images-container{
        display: none;
    }
}


@media (max-width:768px){
    .produttori{
        ::v-deep a:last-child{
            .img{
                height: 120px;
            }
        }
    }
}
</style>